.App,
html,
#root {
	height: 100%;
}

.Footer {
	height: 80px;
	width: 100%;
	display: none;
	bottom: 0;
	position: fixed;
	z-index: 99;
	background-color: #7682f3;
}

.betbook {
	width: 100%;
	height: calc(100dvh - 48px);
}

.App-header {
	background: #040406;
	min-height: 48px;
	display: flex;
	flex-wrap: wrap;
	padding: 0 16px;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #363a49;
}

.break {
	display: none;
}

@media (max-width: 1280px) {
	.Footer {
		display: flex;
	}

	.App-header {
		padding: 0 8px;
	}

	.App-header.beter {
		border-bottom: 1px solid #272727;
		align-content: center;
	}

	.beter .banner {
		display: none;
	}
}

.logo {
	align-self: center;
	cursor: pointer;
}

ul.header-item {
	display: flex;
	flex-direction: row;
	list-style: none;
	padding: 0;
	color: white;
}

.header-item li {
	padding: 0 8px;
	cursor: pointer;
	font-family: 'Roboto';
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	color: #e4dcf5;
	display: flex;
	align-items: center;
}

.header-item li span {
	display: inline-block;
	padding: 0 5px;
}

.header-item li.active {
	color: #fff;
	font-weight: 600;
}

.right {
	display: flex;
	align-items: center;
}

.balance-wrapper {
	display: flex;
	flex-direction: column;
	margin-right: 8px;
}

.balance-title {
	color: #e4dcf5;
	font-size: 9px;
	line-height: 13px;
	font-weight: 600;
	text-align: right;
}

.balance-count {
	color: #e4dcf5;
	font-size: 14px;
	line-height: 17px;
	font-weight: 700;
}

.beter .balance-count,
.beter .balance-title {
	color: #d0d3f0;
}

.balance button:hover {
	background-color: #6773e4;
	cursor: pointer;
}

.gif {
	margin-top: 8px;
}
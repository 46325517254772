.button {
	background-color: #7682f3;
	border: none;
	border-radius: 12px;
	color: white;
	height: 32px;
	font-family: 'Roboto';
	font-weight: 500;
	padding: 0 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.button:hover {
	cursor: pointer;
	background-color: #6773e4;
}

.button.primary {
	background-color: white;
	color: #7682f3;
}

.button.additional,
.button.additional:hover {
	background-color: #1f2029;
	color: #fff;
}

.action,
.beter-action {
	display: block;
	height: 56px;
	border-radius: 12px;
	background-color: #7682f3;
	color: #faf8fa;
	font-size: 16px;
	line-height: 20px;
	width: 100%;
	cursor: pointer;
}

.action:disabled {
	background-color: #24252f;
	color: #494a57;
}

.secondary,
.secondary:hover,
.beter-action,
.beter-action:hover {
	background-color: #e13800;
}

.input-container {
	position: relative;
	width: 100%;
	display: flex;
}

.password {
	position: absolute;
	right: 16px;
	top: 20px;
}

.input-container label {
	position: absolute;
	top: 18px;
	left: 16px;
	color: #d0d3f0;
	pointer-events: none;
	transition: 0.2s ease all;
}

.input-container input {
	border: 1px solid #363a49;
	border-radius: 12px;
	line-height: 16px;
	height: 32px;
	padding: 0 16px;
	background-color: #24252f;
	font-size: 16px;
	line-height: 20px;
	outline: none;
	height: 54px;
	color: #faf8fa;
	margin-bottom: 8px;
	width: 100%;
}

.input-container.focused label {
	left: 12px;
	top: 8px;
	font-size: 12px;
	line-height: 16px;
	padding: 0 5px;
}

.input-container.focused input {
	padding: 26px 16px 8px 16px;
	height: auto;
}

.error.input-container label {
	color: #ff646d;
}

.error.input-container input {
	border-color: #ff646d;
}

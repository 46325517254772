.wrapper {
	display: flex;
	flex-direction: column;
	width: 536px;
	height: 606px;
	background-color: #0f0f0f;
	border-radius: 10px;
	margin: 24px auto;
	color: #faf8fa;
}

@media (max-width: 1280px) {
	.wrapper {
		width: 100%;
		height: 100%;
		margin: 0 auto;
	}
}

.row:last-child {
	margin-top: auto;
	margin-bottom: 32px;
}

.header {
	height: 56px;
	border-bottom: 1px solid #272727;
	display: flex;
	align-items: center;
	padding: 0 18px;
	justify-content: flex-start;
}

.header svg {
	cursor: pointer;
}

.header p {
	margin: auto;
	line-height: 20px;
	font-weight: 600;
	font-size: 16px;
}

.welcome,
.row,
.simple-row {
	width: 80%;
	margin: 0 auto;
}

.simple-row {
	margin: 8px auto 18px auto;
}

.welcome h2 {
	font-weight: 600px;
	font-size: 24px;
	line-height: 30px;
	margin-top: 24px;
}

.welcome p {
	color: #d0d3f0;
	line-height: 17px;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 24px;
}

.row {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}

.row input {
	border: 1px solid #363a49;
	border-radius: 12px;
	line-height: 16px;
	height: 32px;
	padding: 0 16px;
	background-color: #24252f;
	font-size: 16px;
	line-height: 20px;
	outline: none;
	height: 54px;
	color: #faf8fa;
	margin-bottom: 8px;
	width: 100%;
}

.row input:focus {
	border-color: #4b5a91;
}

a {
	color: #7682f3;
	font-size: 16px;
	line-height: 20px;
	padding: 16px 0;
	text-decoration: none;
}

.row a {
	margin: 0;
}

.error {
	color: #ff646d;
	font-size: 13px;
	margin: 0 auto;
	padding-left: 34px;
}

.forgot {
	margin: 16px auto 24px auto;
}

.beter a {
	color: #fff;
}
@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Bold.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.activeElement {
  color: #fbfbfc;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
}

.img {
  margin-right: 8px;
}

.wrapper {
  position: relative;
  padding: 0 12px;
  margin-right: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 52px;
}

.wrapper:hover {
  background-color: #1f2029;
  border-radius: 12px;
  cursor: pointer;
}

.wrapper.active {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #1f2029;
}

.item {
  text-transform: uppercase;
  padding: 0 12px;
  font-size: 14px;
  line-height: 18px;
  height: 32px;
  display: flex;
  align-items: center;
}
.item.with-image {
  display: flex;
  align-items: center;
  padding: 0 0px 0 12px;
}

.item:last-child:hover {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.item:hover {
  background-color: black;
  cursor: pointer;
}

.dd {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #1f2029;
  border-top: 1px solid #35363e;
  color: #fbfbfc;
  line-height: 32px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.container {
  background-color: #14151a;
}

@media (max-width: 1280px) {
  .container {
    height: 100%;
  }
}

.widget {
  margin: auto;
}
